import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import ExpansionItem from '../ExpansionItem';

const nsBase = 'component';
const ns = `${ nsBase }-product-faq`;

const ProductFaq = ({heading = 'FAQs', faqItems}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__container container-fluid`}>
				<div className={`${ ns }__heading`}>
					<h4>{heading}</h4>
				</div>
				<div className={`${ ns }__items`}>
					{
						faqItems.map((item, index) => {
							return (
								<ExpansionItem
									key={uniqueId('violux')}
									id={`faq-item-${ index }`}
									index={index}
									heading={item.question}
									content={item.answer}
								/>
							);
						})
					}
				</div>
			</div>
		</div>
	);
};

export default ProductFaq;
