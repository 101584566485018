import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Image from '../Image';

const nsBase = 'component';
const ns = `${ nsBase }-image-table-text`;

const CopyBlock = props => {
	const {
		image,
		heading,
		description,
		table,
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__image-container`}>
				<Image {...image} />
			</div>
			<div className={`${ ns }__content`}>
				<div className={'container-fluid'}>
					<div className={`${ ns }__content-container`}>
						{heading && (
							<h4 className={`${ ns }__heading`}>
								{heading}
							</h4>
						)}
						{description && (
							<div className={`${ ns }__description`}>
								{description}
							</div>
						)}
						{table && (
							<div className={`${ ns }__table`}>
								{table.map(tableItem => {
									return (
										<div className={`${ ns }__table-item`} key={uniqueId('violux')}>
											{tableItem}
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CopyBlock;
