import React from 'react';
import classNames from 'classnames';

const nsBase = 'component';
const ns = `${ nsBase }-product-technical-specs`;

const ProductTechnicalSpecs = ({ technicalSpecs }) => {
	const {
		size,
		capacity,
		weight,
		powerInput,
		uvcLamp,
		lamps,
		wifiEnabled,
		appEnabled,
	} = technicalSpecs;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__container container-fluid`}>
				<h4 className={`${ ns }__heading`}>
					Technical Specifications
				</h4>
				<div className={`${ ns }__items`}>
					{size && (
						<div className={`${ ns }__item size`}>
							<div className={`${ ns }__item-title`}>
								Size
							</div>
							<div className={`${ ns }__item-spec`}>
								{size}
							</div>
						</div>
					)}
					{uvcLamp && (
						<div className={`${ ns }__item uvc-lamp`}>
							<div className={`${ ns }__item-title`}>
								UV Band & Wavelength
							</div>
							<div className={`${ ns }__item-spec`}>
								{uvcLamp}
							</div>
						</div>
					)}
					{capacity && (
						<div className={`${ ns }__item capacity`}>
							<div className={`${ ns }__item-title`}>
								Capacity
							</div>
							<div className={`${ ns }__item-spec`}>
								{capacity}
							</div>
						</div>
					)}
					{lamps && (
						<div className={`${ ns }__item power-consumption`}>
							<div className={`${ ns }__item-title`}>
								Lamps
							</div>
							<div className={`${ ns }__item-spec`}>
								{lamps}
							</div>
						</div>
					)}
					{weight && (
						<div className={`${ ns }__item weight`}>
							<div className={`${ ns }__item-title`}>
								Weight
							</div>
							<div className={`${ ns }__item-spec`}>
								{weight}
							</div>
						</div>
					)}
					{wifiEnabled && (
						<div className={`${ ns }__item wifi-enabled`}>
							<div className={`${ ns }__item-title`}>
								Wi-Fi Enabled
							</div>
							<div className={`${ ns }__item-spec`}>
								{wifiEnabled}
							</div>
						</div>
					)}
					{powerInput && (
						<div className={`${ ns }__item cord-length`}>
							<div className={`${ ns }__item-title`}>
								Power Input:
							</div>
							<div className={`${ ns }__item-spec`}>
								{powerInput}
							</div>
						</div>
					)}
					{appEnabled && (
						<div className={`${ ns }__item app-enabled`}>
							<div className={`${ ns }__item-title`}>
								Mobile Application
							</div>
							<div className={`${ ns }__item-spec`}>
								{appEnabled}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductTechnicalSpecs;
