import React from 'react';
import classNames from 'classnames';
import Image from '../Image';

const ns = 'application-highlight';

const ApplicationHighlight = ({ image, heading, paragraph }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`container-fluid--no-padding-mobile`]: true,
	});
	return (
		<section className={rootClassnames}>
			<div className={`${ ns }__image-container`}>
				<Image {...image} />
			</div>
			<div className={`${ ns }__text-container container-fluid`}>
				{
					heading && <h4 className={`${ ns }__heading`}>{ heading }</h4>
				}
				{
					paragraph && <p className={`${ ns }__paragraph`}>{ paragraph }</p>
				}
			</div>
		</section>
	);
};

export default ApplicationHighlight;
