/* eslint-disable */
import React, {
	useRef,
	useEffect,
	useContext
} from 'react';
import classNames from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import StoreContext from '../../context/StoreContext';

import Image from '../Image';

gsap.registerPlugin(ScrollTrigger);

const nsBase = 'component';
const ns = `${ nsBase }-product-info-hero`;

const ProductInfoHero = props => {
	const {
		product,
		subtitle,
		slug,
		pdpInfoClick,
		description,
		info,
		image,
		shopifyProductId,
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ slug }`]: slug,
	});

	const titleElement = useRef();
	const subtitleElement = useRef();
	const listElement = useRef();
	const imageElement = useRef();

	const {
		lumaProducts,
	} = useContext(StoreContext);

	useEffect(() => {
		const titleTarget = titleElement ? titleElement.current : null;
		const subtitleTarget = subtitleElement ? subtitleElement.current : null;
		const listTarget = listElement ? listElement.current : null;
		const imageTarget = imageElement ? imageElement.current : null;

		if (titleTarget) {
			gsap.to(titleTarget, {
				// ScrollTrigger settings see link below for all options
				// https://greensock.com/docs/v3/Plugins/ScrollTrigger
				scrollTrigger: {
					// markers: true,
					start: 'top bottom',
					trigger: titleTarget,
					toggleActions: 'play none none reset',
				},
				// setting 'to' / revealed styles here
				y: 0,
				opacity: 1,
				duration: 0.01,
				transition: 'all 1.4s ease',
			});
		}

		if (subtitleTarget) {
			gsap.to(subtitleTarget, {
				// ScrollTrigger settings see link below for all options
				// https://greensock.com/docs/v3/Plugins/ScrollTrigger
				scrollTrigger: {
					// markers: true,
					start: 'top bottom',
					trigger: subtitleTarget,
					toggleActions: 'play none none reset',
				},
				// setting 'to' / revealed styles here
				y: 0,
				opacity: 1,
				duration: 0.01,
				transition: 'all 1.4s ease',
			});
		}

		if (listTarget) {
			gsap.to(listTarget, {
				// ScrollTrigger settings see link below for all options
				// https://greensock.com/docs/v3/Plugins/ScrollTrigger
				scrollTrigger: {
					// markers: true,
					start: 'top bottom',
					trigger: listTarget,
					toggleActions: 'play none none reset',
				},
				// setting 'to' / revealed styles here
				y: 0,
				opacity: 1,
				duration: 0.01,
				transition: 'all 1.4s ease',
			});
		}

		if (imageTarget) {
			gsap.to(imageTarget, {
				// ScrollTrigger settings see link below for all options
				// https://greensock.com/docs/v3/Plugins/ScrollTrigger
				scrollTrigger: {
					// markers: true,
					start: 'top bottom',
					trigger: imageTarget,
					toggleActions: 'play none none reset',
				},
				// setting 'to' / revealed styles here
				transform: 'none',
				opacity: 1,
				delay: 1.4,
				duration: 0.01,
				transition: 'all 1.4s ease',
			});
		}
	}, []);

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<div className={`${ ns }__container`}>
					<div className={`${ ns }__left`}>
						{product.title && (
							<h1 className={`${ ns }__title`} ref={titleElement}>
								{product.title}
							</h1>
						)}
						<div className={`yotpo bottomLine`} id={'yotpo-star-rating'} data-yotpo-product-id={shopifyProductId} />
						{/* {subtitle && (
							<div className={`${ ns }__subtitle`} ref={subtitleElement}>
								{subtitle}
							</div>
						)} */}

						<div className={`${ ns }__buttons`}>
							{lumaProducts && Object.keys(lumaProducts).map(productKey => {
								const lumaProduct = lumaProducts[productKey];
								const activeClass = lumaProduct.handle === slug ? 'active' : '';
								const { price, compareAtPrice } = lumaProduct.variants[0];

								// eslint-disable-next-line
								var comingSoon = false;
								if (lumaProduct.title === 'Luma') {
									var comingSoon = true;
								} else {
									var comingSoon = false;
								}

								return (
									<button
										onClick={() => { pdpInfoClick(lumaProduct.handle); }}
										className={`${ ns }__button ${ activeClass }`}
										key={lumaProduct.handle}
									>
										<span className={`${ ns }__button-title`}>{lumaProduct.title}</span>

										{
											comingSoon ? (
												<p className={`${ ns }__price sale`}>
													<span className={`${ ns }__button-price`}>Coming Soon</span>
												</p>
											) : (
												<div className={`${ ns }__button-price`}>
													{compareAtPrice && <span className={`${ ns }__button-price--sale sale`}>${compareAtPrice}</span>}${price}
												</div>
											)
										}

									</button>
								);
							})}
						</div>
						{description && (
							<div className={`${ ns }__description`}>
								{description}
							</div>
						)}
						{info && (
							<div className={`${ ns }__info`} ref={listElement}>
								{info.map(infoItem => {
									return (
										<div className={`${ ns }__info-item`} key={infoItem}>
											{infoItem}
										</div>
									);
								})}
							</div>
						)}
					</div>
					<div className={`${ ns }__right`}>
						{image && (
							<div className={`${ ns }__image`} ref={imageElement}>
								<Image {...image} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductInfoHero;
