import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import PlusSign from '../../icons/plus-sign';

const nsBase = 'component';
const ns = `${ nsBase }-expansion-item`;

const ExpansionItem = props => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		id,
		index,
		heading,
		content,
	} = props;

	const [expanded, setExpanded] = useState('');
	const [iconRotate, setIconRotate] = useState('');
	const [contentHeight, setContentHeight] = useState('0px');
	const expandPanel = useRef(null);

	const toggleExpanded = () => {
		setExpanded(expanded === '' ? 'expanded' : '');
		setIconRotate(expanded === '' ? 'rotate' : '');
		setContentHeight(expanded === '' ? `${ expandPanel.current.scrollHeight }px` : '0px');
	};

	return (
		<div className={rootClassnames}>
			<button
				className={`${ ns }__heading`}
				aria-controls={id}
				aria-expanded={expanded !== ''}
				onClick={toggleExpanded}
			>
				<span className={`${ ns }__heading-label`}>
					<h5>{`${ index + 1 }. ${ heading } `}</h5>
				</span>
				<span className={`${ ns }__heading-icon ${ iconRotate }`}>
					<PlusSign />
				</span>
			</button>
			<div
				className={`${ ns }__content ${ expanded }`}
				style={{maxHeight: `${ contentHeight }`}}
				id={id}
				ref={expandPanel}
			>
				<div className={`${ ns }__content-body`}>
					<p>{ content }</p>
				</div>
			</div>
		</div>
	);
};

export default ExpansionItem;
