import React from 'react';

const YotpoReviews = ({ shopifyProductId, currentProduct }) => {
	return (
		<div
			id={'yotpo-reviews'}
			className={'container-fluid--important yotpo yotpo-main-widget'}
			data-product-id={shopifyProductId}
			data-price={currentProduct.price}
			data-currency={'USD'}
			data-name={currentProduct.title}
			data-url={`https://www.violux.com/product/${ currentProduct.slug }/`}
			data-image-url={`https://www.violux.com/product/${ currentProduct.slug }/`}
		/>
	);
};

export default YotpoReviews;
