import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Helmet from 'react-helmet';
import { decode } from 'js-base64';

import SEO from '../components/SEO';
import ProductInfoHero from '../components/ProductInfoHero';
import ProductFaq from '../components/ProductFaq';
import AlternatingImageText from '../components/AlternatingImageText';
import ThreeTextUp from '../components/ThreeTextUp/ThreeTextUp';
import MediaTextFullWidth from '../components/MediaTextFullWidth';
// import Slider from '../components/Slider/Slider';
import StoreContext from '../context/StoreContext';
import ProductTechnicalSpecs from '../components/ProductTechnicalSpecs';
// import SliderArrow from '../icons/slider-arrow';
import ImageTableText from '../components/ImageTableText';
import ApplicationHighlight from '../components/ApplicationHighlight';
import YotpoReviews from '../components/YotpoReviews';

const nsBase = 'page';
const ns = `${ nsBase }-product-luma`;

const Luma = props => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		data,
	} = props;

	const {
		products,
		setLumaProducts,
		setCurrentLumaProduct,
	} = useContext(StoreContext);

	// Decodes Storefront Base64 Product ID to Shopify's ID which is needed for Yotpo Reviews
	const decodeShopifyProductId = shopifyProduct => {
		const decodedCheckoutIdHash = decode(shopifyProduct.shopifyId);
		const productId = decodedCheckoutIdHash.split('/')[decodedCheckoutIdHash.split('/').length - 1];
		return productId;
	};

	const [currentProduct, setCurrentProduct] = useState(products.lumaPro);
	const [currentShopifyProduct, setCurrentShopifyProduct] = useState(data.lumaPro);
	const [shopifyProductId, setShopifyProductId] = useState(decodeShopifyProductId(data.lumaPro));

	const pdpInfoClick = product => {
		if (product === 'luma-pro-plus') {
			window.history.replaceState(null, null, `/product/luma-pro-plus`);
			setCurrentProduct(products.lumaProPlus);
			setCurrentShopifyProduct(data.lumaProPlus);
			setCurrentLumaProduct(data.lumaProPlus);
			setShopifyProductId(decodeShopifyProductId(data.lumaProPlus));
		} else {
			window.history.replaceState(null, null, `/product/luma-pro`);
			setCurrentProduct(products.lumaPro);
			setCurrentShopifyProduct(data.lumaPro);
			setCurrentLumaProduct(data.lumaPro);
			setShopifyProductId(decodeShopifyProductId(data.lumaPro));
		}
	};

	const renderInfoSnippet = (lineBreak = false) => {
		return (
			<>
				{/* eslint-disable-next-line */}
				<span className={`${ ns } bold`}>{ currentProduct.infoSnippet.UVCpower }</span> of UV-C Power {lineBreak ? <br /> : <span className={`${ ns }__slash`}>/</span>} <span className={`${ ns } bold`}>{ currentProduct.infoSnippet.cleanCycle }</span> clean cycle
			</>
		);
	};

	// Add To Cart Functionality START
	const {
		addVariantToCart,
		store: { client },
		toggleCartFlyout,
	} = useContext(StoreContext);

	const {
		variants: [initialVariant],
	} = currentShopifyProduct;

	// eslint-disable-next-line max-len
	const productVariant = client.product.helpers.variantForOptions(currentShopifyProduct, initialVariant) || initialVariant;

	const handleAddToCart = () => {
		toggleCartFlyout(1000);
		const quantity = 1;
		addVariantToCart(productVariant.shopifyId, quantity);
	};
	// Add To Cart Functionality END

	useEffect(() => {
		setLumaProducts(data);
		setCurrentProduct(props.uri === '/product/luma-pro-plus' ? products.lumaProPlus : products.lumaPro);
		setCurrentShopifyProduct(props.uri === '/product/luma-pro-plus' ? data.lumaProPlus : data.lumaPro);
		setCurrentLumaProduct(props.uri === '/product/luma-pro-plus' ? data.lumaProPlus : data.lumaPro);
		setShopifyProductId(decodeShopifyProductId(props.uri === '/product/luma-pro-plus' ? data.lumaProPlus : data.lumaPro));
	}, []); // eslint-disable-line

	// Refreshes Yotpo Widgets everytime the current product (id) is changed
	useEffect(() => {
		if (typeof window.yotpo !== 'undefined') {
			// eslint-disable-next-line no-undef
			const api = new Yotpo.API(yotpo);
			api.refreshWidgets();
		}
	}, [shopifyProductId]);

	return (
		<div className={rootClassnames}>
			<Helmet>
				<script type={'text/javascript'}>
					{`(function e(){var e=document.createElement("script");e.type="text/javascript",e.async=true,e.src="//staticw2.yotpo.com/5i0ubpudIlMHnE0OhtAjtCPZDNO40zILuVgY3gwe/widget.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)})();`}
				</script>
			</Helmet>
			<SEO title={'Luma'} />
			<ProductInfoHero
				product={currentShopifyProduct}
				shopifyProductId={shopifyProductId}
				{...currentProduct} // eslint-disable-line
				pdpInfoClick={pdpInfoClick}
			/>
			<ThreeTextUp
				modifier={currentProduct.slug}
				eyebrow={'Smart UV-C Sanitizer'}
				heading={(
					<>
						{`${ currentProduct.title }.`}
						<span className={'color--blue-blizzard'}>
							{currentProduct.threeTextUpCopy}
						</span>
					</>
				)}
				blocks={[
					{
						icon: '/assets/images/icons/germ_white.png',
						heading: 'Kills 99.9%* of germs, \nbacteria and viruses.',
						description: 'Breakthrough UV-C light technology powerfully disinfects by destroying the DNA and RNA of dangerous pathogens.',
					},
					{
						icon: '/assets/images/icons/tested_white.png',
						heading: 'Laboratory tested \nand certified.',
						description: 'Our devices have been independently tested and certified by a third-party IAC certified medical laboratory.',
					},
					{
						icon: '/assets/images/icons/eco_white.png',
						heading: 'Chemical-free, ozone-free \nand eco-friendly. ',
						description: 'Unlike chemical disinfectants, UV-C light doesn’t leave behind any chemical residue and is safe for use on any item.',
					},
				]}
			/>
			<MediaTextFullWidth
				hTag={'h3'}
				heading={'Disinfect with a press of a button.'}
				video={'/assets/videos/Violux_Video_4_compressed.mp4'}
			/>
			<MediaTextFullWidth
				modifier={'more-space'}
				hTag={'h3'}
				heading={'More space. More clean.'}
				description={'Luma’s unique design provides superior coverage and energy saturation for cleaning large objects or multiple objects simultaneously. Disinfect anything from phones, tablets and remotes, to children\'s toys, pacifiers, bottles, pet toys and more. Luma is even food-safe.'}
				image={currentProduct.imageTextStacked}
			/>
			<ThreeTextUp
				blocks={[
					{
						icon: '/assets/images/icons/360_white.png',
						heading: '360°',
						description: 'Disinfection Coverage',
					},
					{
						icon: '/assets/images/icons/cubic_white.png',
						heading: '2,210',
						description: 'Cubic Inch\nCapacity',
					},
					{
						icon: '/assets/images/icons/clock_white.png',
						heading: currentProduct.cleaningTime,
						description: `${ currentProduct.id === 1 ? 'Fast Clean\nCycle' : 'Ultra Fast\nClean Cycle' }`,
					},
				]}
				modifier={'blue'}
			/>
			<AlternatingImageText
				heading={'Harness the cleaning power of UV-C light.'}
				paragraph={'Luma’s powerful ultraviolet reactor and design is engineered to provide enhanced light focus and strength for maximum disinfection.'}
				altText={'Cleaning power'}
				mainImageMobile={'/assets/images/pdp/harness_the_cleaning_power.jpg'}
				dataMedia
				imgOnLeft
				infoSnippets={[
					{
						heading: currentProduct.lampCount,
						paragraph: 'UV-C Lamps',
					},
					{
						heading: currentProduct.watts,
						paragraph: 'Reactor',
					},
				]}
				cta={{
					link: '/technology',
					label: 'Learn More about our technology',
				}}
			/>
			<AlternatingImageText
				heading={'Kills 99.9%* of germs, bacteria and viruses.'}
				paragraph={'Effectively and safely sterilize the surface of everyday items in as little as 3 minutes.'}
				altText={'Luma product'}
				mainImageMobile={'/assets/images/pdp/kills99.png'}
				dataMedia
				last={currentProduct.slug !== 'luma-pro-plus'}
			/>
			<ApplicationHighlight
				heading={'Peace of mind in the \npalm of your hand.'}
				paragraph={'Schedule reminders, receive notifications, track usage and keep up-to-date with the latest health insights, all from our mobile app.'}
				image={{
					altText: 'iPhone app',
					mainImageMobile: '/assets/images/pdp/iphone-app.png',
				}}
			/>
			<ImageTableText
				heading={'For anywhere you need the confidence of clean.'}
				description={'Luma’s timeless design fits into the décor of any location and is built to withstand the demands of high-volume use.'}
				image={{
					altText: 'Luma product',
					mainImageMobile: '/assets/images/pdp/for_anywhere_you_need_clean.jpg',
				}}
				table={[
					'Home',
					'Hospitality',
					'Office',
					'Education',
					'Retail',
					'Medical',
				]}
			/>
			{/* <Slider
				heading={'Designed with purpose.'}
				slides={[
					{
						image: {
							mainImageMobile: '/assets/images/luma-slide.jpg',
							altText: 'Luma product',
						},
						description: (
							<p>
								<strong>Lorem ipsum dolor sit amet. </strong>
								Consectetur adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna.
							</p>
						),
					},
					{
						image: {
							mainImageMobile: '/assets/images/luma-slide.jpg',
							altText: 'Luma product',
						},
						description: (
							<p>
								<strong>Lorem ipsum dolor sit amet. </strong>
								Consectetur adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna.
							</p>
						),
					},
					{
						image: {
							mainImageMobile: '/assets/images/luma-slide.jpg',
							altText: 'Luma product',
						},
						description: (
							<p>
								<strong>Lorem ipsum dolor sit amet. </strong>
								Consectetur adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna.
							</p>
						),
					},
					{
						image: {
							mainImageMobile: '/assets/images/luma-slide.jpg',
							altText: 'Luma product',
						},
						description: (
							<p>
								<strong>Lorem ipsum dolor sit amet. </strong>
								Consectetur adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna.
							</p>
						),
					},
				]}
				sliderSettings={{
					speed: 700,
					prevArrow: <SliderArrow direction={'back'} />,
					nextArrow: <SliderArrow />,
					responsive: [
						{
							breakpoint: 992,
							settings: {
								arrows: false,
								dots: true,
								infinite: true,
								slidesToShow: 1,
								slidesToScroll: 1,
							},
						},
						{
							breakpoint: 9999,
							settings: {
								arrows: true,
								dots: false,
								infinite: true,
								centerMode: true,
								slidesToShow: 1,
								slidesToScroll: 1,
								centerPadding: '150px',
							},
						},
					],
				}}
				showPagination
				modifier={'product-slider'}
			/> */}
			<YotpoReviews
				shopifyProductId={shopifyProductId}
				currentProduct={currentProduct}
			/>
			<ProductTechnicalSpecs
				technicalSpecs={currentProduct.technicalSpecs}
			/>
			<ProductFaq
				faqItems={currentProduct.faqItems}
			/>
			<MediaTextFullWidth
				modifier={'pdp-luma'}
				heading={currentProduct.title}
				description={renderInfoSnippet()}
				image={currentProduct.pdpLastModule}
				handleAddToCart={handleAddToCart}
				productSlug={currentProduct.slug}
			/>
		</div>
	);
};

export default Luma;

export const productLumaQuery = graphql`
	query GET_LUMA_LUMAPRO {
		lumaPro: shopifyProduct(handle: { eq: "luma-pro" }) {
			id
			title
			handle
			productType
			description
			descriptionHtml
			shopifyId
			options {
				id
				name
				values
			}
			variants {
				id
				title
				price
				availableForSale
				shopifyId
				selectedOptions {
					name
					value
				}
			}
			priceRange {
				minVariantPrice {
					amount
					currencyCode
				}
				maxVariantPrice {
					amount
					currencyCode
				}
			}
			images {
				originalSrc
				id
				localFile {
					childImageSharp {
						fluid(maxWidth: 910) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		}
		lumaProPlus: shopifyProduct(handle: { eq: "luma-pro-plus" }) {
			id
			title
			handle
			productType
			description
			descriptionHtml
			shopifyId
			options {
				id
				name
				values
			}
			variants {
				id
				title
				price
				availableForSale
				shopifyId
				selectedOptions {
					name
					value
				}
			}
			priceRange {
				minVariantPrice {
					amount
					currencyCode
				}
				maxVariantPrice {
					amount
					currencyCode
				}
			}
			images {
				originalSrc
				id
				localFile {
					childImageSharp {
						fluid(maxWidth: 910) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		}
	}
`;
