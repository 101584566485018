import React from 'react';

const PlusSign = ({fill = `#003370`}) => {
	return (
		<svg width={`32`} height={`32`} xmlns={`http://www.w3.org/2000/svg`}>
			<g fill={fill} fillRule={`evenodd`}>
				<path d={`M17 2v28h-2V2z`} />
				<path d={`M30 15v2H2v-2z`} />
			</g>
		</svg>
	);
};

export default PlusSign;
